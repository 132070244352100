<template>
<div>
     
  <b-card body-class="p-1">

    <div class="d-flex transition-all-02s" :class="{'mr-2': $route.name == 'middlewares'}" v-if="transmission && transmission.id == $route.params.transmissionID">
      <!--  -->
      <b-img class="transmission-profile-image cursor-pointer" 
        :key="'transmission-img-'+transmission.identifier"
        :src="getImage(`transmission-image-${transmission.identifier}`)"
        @click="redirectToInfo()"
      />
      <!--  -->
      
      <div class="pl-1 d-flex justify-content-between w-100">
        
        <div class="flex-vertical-between"> <!-- --- LEFT --- -->
          <div>
            <div class="font-weight-bold text-light">
              {{transmission.organization.name}}
            </div>
            <div>
              <h3 class="mb-0 text-light font-weight-bolder text-break cursor-pointer w-fit-content" @click="redirectToInfo()">{{transmission.name}}</h3>
            </div>
          </div>
          
          <div>
            <b-button variant="indigo" class="shadow-sm"  :to="{name: 'middlewares'}">
              <span class="text-light">
                <feather-icon icon="GitPullRequestIcon"/>
                Middlewares
              </span>
            </b-button>
          </div>
        </div>

        <div class="flex-vertical-between" align="right"> <!-- --- RIGHT --- -->
          <div class="d-flex align-items-start justify-content-end">
            <div class="mr-1 mt-75" v-if="currentEnv"> 
              Current Env <b-icon icon="arrow-right-short"/>
              <router-link to="environments" class="text-reset">
                <span class="font-weight-bolder hover-underline" to=""> {{currentEnv.name}} </span>
              </router-link>
            </div>
            <compile-button/>
          </div>

          <div class="d-flex align-items-center">
            <div v-if="isDevServer || userIsGod">
              <b-button variant="none" :to="{name: 'middlewares-debug'}" class="round px-50 py-50 debug-middlewares-btn">
                <b-icon icon="bug-fill" variant="success"/>
              </b-button>
            </div>
            <div v-for="(button, key) in buttons" :key="`btn-tg-nav-${key}`">
              <span @mouseover="openDropdown('dropdown-nav'+key)" @mouseleave="delayCloseDropdown('dropdown-nav'+key)">
                <b-dropdown
                  class="ml-1"
                  :id="`dropdown-tg-nav-${key}`"
                  :text="button.text"
                  :variant="button.variant"
                  :ref="'dropdown-nav'+key"
                >
                  <b-dropdown-item
                    v-for="(data, dp_key) in button.items"
                    :to="data.action"
                    :key="`btn-tg-nav-${dp_key}`"
                  >
                    <feather-icon
                      class="mr-50"
                      v-if="data.icon"
                      :icon="data.icon"
                    />
                    {{ data.text }}
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </div>
          </div>

        </div>
        
      </div>

    </div>

    <div  class="d-flex justify-content-between" v-else>
      <div class="d-flex">
        <b-skeleton height="100px" width="100px" class="mb-0 mr-2"/>
        <div>
          <b-skeleton height="20px" width="100px" class="mb-50 "/>
          <b-skeleton height="23px" width="500px" class="mb-1"/>
          <b-skeleton height="35px" width="150px" class="mb-0"/>
        </div>
      </div>
      <div align="right">
        <div>
          <b-skeleton height="35px" width="120px" class="mb-0"/>
        </div>
        <div class="d-flex mt-2">
          <b-skeleton height="35px" width="120px" class="mb-0 ml-1"/>
          <b-skeleton height="35px" width="120px" class="mb-0 ml-1"/>
          <b-skeleton height="35px" width="120px" class="mb-0 ml-1"/>
        </div>
      </div>
    </div>

  </b-card>
</div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BSkeleton,
  BDropdown,
  BIcon,
  BCard,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import CompileButton from "./CompileButton.vue";
import Helper from "@/layouts/components/Helper.vue";
import EventBus from "@/custom/eventBus";
import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdown,
    BDropdownDivider,
    BAvatar,
    BButton,
    BSkeleton,
    vSelect,
    CompileButton,
    Helper,
    BIcon,
    BCard,
    BImg,
  },
  data() {
    return {
      options: [],
      currentEnv: undefined,
      transmissions: null,
      fetchingTransmission: false,
      dropdownTimer: undefined
    };
  },
  computed: {
    ...mapGetters(['getSelectedTransmissionGroup', 'getImage','getLoggedUser']),
    ...mapGetters('internal' , ['getReactiveEnv']),
    transmissionID() {
      return this.$route.params.transmissionID
    },
    transmission() {
      return this.getSelectedTransmissionGroup;
    },
    isDevServer() {
      return process.env.NODE_ENV === 'development' || window.location.href.includes('homolog')
    },
    userIsGod(){
      return this.getLoggedUser?.ability?.id == 666
    },
    buttons(){
      return [
        {
          text: "Central",
          variant: "outline-primary",
          items: [
            {
              text: "Info",
              icon: "AlertCircleIcon",
              action: {
                name: "transmission-info",
                params: {
                  groupId: this.transmissionID,
                },
              },
            },
            {
              text: "History",
              icon: "ClockIcon",
              action: {
                name: "transmission-history",
              },
            },
            {
              text: "Dashboard",
              icon: "ActivityIcon",
              action: {
                name: "transmission-dashboard",
              },
            },
          ],
        },
        {
          text: "Setup",
          variant: "outline-warning",
          items: [
            {
              text: this.$t("transmission.nav.events"),
              icon: "RadioIcon",
              action: {
                name: "events",
              },
            },
            {
              text: this.$t("transmission.nav.services"),
              icon: "BriefcaseIcon",
              action: {
                name: "services",
              },
            },
            {
              text: this.$t("transmission.nav.environments"),
              icon: "KeyIcon",
              action: {
                name: "environments",
              },
            },
            {
              text: this.$t("transmission.nav.internal_storage"),
              icon: "DatabaseIcon",
              action: {
                name: "internal-storage",
              },
            },
            {
              text: this.$t("transmission.nav.scripts"),
              icon: "FileIcon",
              action: {
                name: "scripts",
              },
            },
            {
              text: this.$t("transmission.nav.apps"),
              icon: "GlobeIcon",
              action: {
                name: "integrate-apps",
              },
            },
          ],
        },
        {
          text: "Activators",
          variant: "outline-info",
          items: [
            {
              text: this.$t("transmission.nav.webhooks"),
              icon: "LinkIcon",
              action: {
                name: "transmission-webhooks",
              },
            },
            {
              text: this.$t("transmission.nav.scheduler"),
              icon: "ClockIcon",
              action: {
                name: "transmission-scheduler",
              },
            },
          ]
        }
      ];
    }
  },
  mounted() {
    this.init();

    EventBus.$on("reloadEnvs", () => {
      this.fetchAllEnvironments();
    });
  },
  beforeDestroy() {
    EventBus.$off("reloadEnvs");
  },
  watch: {
    currentEnv() {
      const transmissionId = this.transmissionID
      localStorage.setItem(
        `currentEnv-${transmissionId}`,
        JSON.stringify(this.currentEnv)
      );
    },
    getReactiveEnv(){
      const stored = localStorage.getItem(`currentEnv-${this.transmissionID}`)
      if (stored && stored.length){
        let env = JSON.parse(stored)
        this.currentEnv = env
      }
    }
  },
  methods: {
    init() {
      if (!this.getSelectedTransmissionGroup || this.getSelectedTransmissionGroup.id != this.transmissionID) {
        this.fetchTransmissionGroupByOrganization()
      }
      this.fetchAllEnvironments();
    },
    fetchTransmissionGroupByOrganization() {
      if(this.$route.meta.loadTransmissionGroup){
        this.fetchingTransmission = true;
        this.$store.dispatch('getTransmissionGroupById', { transmissionID: this.transmissionID } ).then(() => {
          this.fetchingTransmission = false;
        })
        .catch((err) => {
          console.error(err)
        })
      }
    },
    fetchAllEnvironments() {
      this.$store
        .dispatch("getAllEnvironmentsByTransmissionGroup", { per_page: 100, transmissionID: this.transmissionID })
        .then((response) => {          
          if (Array.isArray(response)){

            const transmissionId = this.transmissionID
              
            let selectedEnv = localStorage.getItem(
              `currentEnv-${transmissionId}`
              );
              if (selectedEnv) {
                selectedEnv = JSON.parse(selectedEnv);
                
                const res = response.filter((value) => {
                  return value.id == selectedEnv.id;
                });
                
              if (res.length == 0) {
                selectedEnv = undefined;
              }
            }
            
            if (!selectedEnv) {
              selectedEnv = response[0] || undefined;
            }
            
            this.currentEnv = selectedEnv;
            this.options = response;
         } else {
          
          const transmissionId = this.transmissionID

          let selectedEnv = localStorage.getItem(
            `currentEnv-${transmissionId}`
          );
          if (selectedEnv) {
            selectedEnv = JSON.parse(selectedEnv);

            const res = response.items.filter((value) => {
              return value.id == selectedEnv.id;
            });

            if (res.length == 0) {
              selectedEnv = undefined;
            }
          }

          if (!selectedEnv) {
            selectedEnv = response.items[0] || undefined;
          }

          this.currentEnv = selectedEnv;
          this.options = response.items;
         }
        })
        .catch((err) => {
          console.error(err)
        });
    },
    openDropdown(ref){
      clearTimeout(this.dropdownTimer)
      this.$refs[ref][0].show()
    },
    delayCloseDropdown(ref){
      clearTimeout(this.dropdownTimer)
      this.dropdownTimer = setTimeout(() => {
        if (this.$refs[ref] && this.$refs[ref][0]){
          this.$refs[ref][0].hide()
        }
      }, 300);
    },
    redirectToInfo(){
      if(this.$route.name != 'transmission-info'){ //avoid redundant $router push
        this.$router.push({name: "transmission-info", params: {...this.$route.params}} )
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

.transition-all-02s{
  transition: all 0.2s;
}

.flex-vertical-between{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.transmission-profile-image{
  $img-size: 100px;
  width: $img-size;
  max-width: $img-size;
  min-width: $img-size;
  min-height: $img-size;
  max-height: $img-size;
  object-fit: cover;
  border-radius: 6px;
  background-color: transparentize($black, 0.7);

  border: 1px solid transparentize($light, 0.9);
}

.debug-middlewares-btn{
  background-color: transparent;
  border: 1px solid $light;
  *{
    color: $light;
    transition: all 0.2s;
  }
  transition: all 0.2s;
  &:hover{
    background-color: $light;
    *{
      color: $black;
    }
  }
}
</style>