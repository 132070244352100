<template>
  <div>
  <b-collapse :visible="cardIsVisible" appear>
    <div class="pt-1"></div>
    <div class="custom-run-card pb-1 "  @click="openTab()">
      <div align="right">
        <b-button class=" close-card-button" variant="none" @click.stop="closeThisCard()">
          <b-icon icon="x" variant="secondary" scale="1"/>
        </b-button>
      </div>

      <h6 class="d-flex justify-content-between">
        <div>
          <div class="bg-dark text-light rounded px-50 py-25 font-weight-bolder">
            Run
            {{ getRunIdentifier() }}
          </div>
        </div>
        <div>
          <span class="border-bottom-secondary mt-0">{{$t('terminal_tests.terms.view_details')}}</span>
        </div>
      </h6>

      <!-- <div class="text-secondary">
        <div class="d-inline-block" v-if="run.origin.receiver">
          <p class="m-0 d-inline-block mr-1" v-if="run.origin.receiver">
            {{$t('terminal_tests.terms.receiver_run_list')}} <span class="text-reset-color">{{ run.origin.receiver }}</span>
          </p>
          |
        </div>
        <div class="d-inline-block" v-if="run.origin.service">
          <p class="m-0 d-inline-block ml-1 mr-1" v-if="run.origin.service">
            {{$t('terminal_tests.terms.sender_run_list')}} {{ run.origin.service }}
          </p>  
          |
        </div>
        <div class="d-inline-block" v-if="run.origin.event">
          <p class="m-0 d-inline-block mr-1">
            {{$t('terminal_tests.terms.event_run_list')}} {{ run.origin.event }}
          </p>
          |
        </div>
        <div class="d-inline-block" v-if="run.webhook">
          <p class="m-0 d-inline-block mr-1">
            {{$t('terminal_tests.terms.webhook_run_list')}} {{ run.webhook.identifier }}
          </p>
          |
        </div>
        <p class="m-0 d-inline-block ml-1">
          {{$t('terminal_tests.terms.total_time')}} {{ (run.time / (1000 * 1000)).toLocaleString() }}s
        </p>
      </div> -->
      <!-- <p class="m-0">{{$t('terminal_tests.terms.executed_at')}} {{ formatDate(run.created_at) }}</p> -->

      <div class="d-flex justify-content-between align-items-start flex-wrap">
        <div class="small font-weight-bolder text-favorite">
          <!-- <b-icon icon="cursor-fill"/> -->
          Event
          <b-icon icon="arrow-right-short" scale="1.2"/>
          {{run.origin.event}}
        </div>
        

        <div class="text-info small font-weight-bolder">
          <b-icon icon="stopwatch" scale="1.3"/> {{ (run.time / (1000 * 1000)).toFixed(1).toLocaleString() }}s
        </div>
      </div>
      
      <div class="d-flex align-items-center card-hr-divider">
        <hr class="m-0 w-100 border-color-secondary">
        <b-icon icon="triangle-fill" scale="0.5" rotate="180" class="mx-50"/>
        <hr class="m-0 w-100 border-color-secondary">
      </div>

      <div>
        <div class="d-flex justify-content-between">
          <div class="font-weight-bolder">
            Return
          </div>
          <div class="small text-secondary">
            Middlewares executed: {{run.middleware_executed}}
          </div>

        </div>

        <div @click.stop>
            <!-- v-if="run.json_return.info.RETURN && run.json_return.info.RETURN.BODY" -->
          <custom-pre
            class="rounded bg-darker"
            maxHeight="150px"
            :data="run.json_return.info.RETURN.BODY"
          />
          <!-- <p v-else class="undefined-pre">undefined</p> -->
        </div>
      </div>
    </div>
  </b-collapse>
  </div>
</template>

<script>
import { BCollapse, BButton } from "bootstrap-vue";
import JsonViewer from "vue-json-viewer";
import Run from "./class/run";
import moment from "moment";
import CustomPre from "@/views/pages/middleware/MiddlewareManagement/Components/CustomPre.vue"

export default {
  components: {
    JsonViewer,
    BCollapse,
    BButton,
    CustomPre
  },
  props: {
    run: {
      type: Run,
      required: true,
    },
  },
  data() {
    return {
      cardIsVisible: true,
    };
  },
  methods: {
    getRunIdentifier() {
      return `${this.run.index + 1}`;
    },
    openTab() {
      
      this.$emit("addTab", {
        key: `run.${this.run.index}.tab.details`,
        name: `Run ${this.getRunIdentifier()} - Details`,
        component: "RunLogInfo",
        closable: true,
        createOpenned: true,
        properties: {
          run: this.run,
          isTerminal: true,
        },
      });
    },
    formatDate(value) {
      if (!value) return null
      let date = new Date(value)
      return moment(date).format(this.$t('scheduler.view.moment_date_format'));
    },
    closeThisCard(){
      this.cardIsVisible = false
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/test-terminal.scss';

.bg-darker{
  background-color: transparentize($black, 0.05);
}
.custom-run-card {
  font-family: monospace;
  position: relative;
  background-color: transparentize($secondary, 0.9);
  padding: 10px;
  border-radius: 5px;
  border: 1px solid $dark;
  margin-bottom: 10px;
  transition: all 0.2s;
  cursor: pointer;
  &:hover{
    background-color: transparentize($secondary, 0.8);
  }
}

.btn-run-card {
  display: inline-block;
  margin-top: 5px;
  text-decoration: underline;
  text-underline-offset: 3px;
  cursor: pointer;
  transition: all 0.1s ease-in;

  &:hover, &:focus {
    opacity: 0.7;
  }
}

.close-card-button{
  padding: 5px;
  position: absolute;
  top: -23px;
  right: 0px;
  background-color: transparent;
  border-radius: 8px;
  transition: all 0.2s;
  &:hover{
    color: $danger;
  }
}

.card-hr-divider{
  $divider-color: transparentize($secondary, 0.8);
  color: $divider-color;
  hr{ border-color: $divider-color;}
}

</style>