<template>
  <div class="w-100">
    <div class="navbar-container d-flex content align-items-center">
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link class="nav-link" @click="toggleVerticalMenuActive">
            <feather-icon icon="MenuIcon" size="21" />
          </b-link>
        </li>
      </ul>

      <!-- Left Col -->
      <div
        class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
      >
        <!-- <dark-Toggler class="d-none d-lg-block" /> -->
        <div class="ml-2" v-if="getIsDebugMode">
          <b-button
            variant="relief-primary"
            class="btn-icon"
            @click="leaveUserSession()"
          >
            {{ $t("navbar.debug_mode.leave_session") }}
          </b-button>
        </div>
      </div>

      <b-navbar-nav
        class="nav align-items-center d-flex justify-content-between ml-auto"
      >
        <small class="text-secondary"> {{ $t("navbar.organization.label") }} &nbsp; </small>
        <organization-select />
        <locale />
        
        <notification-bell class="mr-50"/>

        <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
        >
          <template #button-content v-if="loggedUser != []">
            <div class="d-sm-flex d-none user-nav">
              <p class="user-name font-weight-bolder mb-0">
                {{ loggedUser.username ? loggedUser.username : "" }}
              </p>
              <span class="user-status">{{
                loggedUser.ability ? loggedUser.ability.label : ""
              }}</span>
            </div>
            <!-- <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          /> -->
            <Gravatar
              class="avatar"
              :email="loggedUser.email ? JSON.stringify(loggedUser.email) : ''"
              alt="Nobody"
              :size="36"
            />
            <!-- :src="require('@/assets/images/avatars/13-small.png')" -->
          </template>

          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="profile()"
          >
            <feather-icon size="16" icon="UserIcon" class="mr-50" />
            <span>{{ $t('navbar.user_dropdown.profile') }}</span>
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout()"
          >
            <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
            <span>{{ $t('navbar.user_dropdown.logout') }}</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>

        

      </b-navbar-nav>
    </div>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { initialAbility } from "@/libs/acl/config";
import Gravatar from "vue-gravatar";
import { makeToast } from "@/layouts/components/Popups";
import custom from "@/custom";
import { mapGetters } from "vuex";
import Locale from "@/layouts/components/Locale.vue";
import OrganizationSelect from "@/layouts/components/OrganizationSelect.vue";
import Nav from "./Transmission/TransmissionNav.vue";
import NotificationBell from "@/layouts/components/NotificationBell.vue"

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    Gravatar,
    Locale,
    // Navbar Components
    DarkToggler,
    OrganizationSelect,
    Nav,
    NotificationBell,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loggedUser: [],
    };
  },
  computed: {
    ...mapGetters(["getLoggedUser", "getIsDebugMode"]),
  },
  mounted() {
    this.getUserData();
  },
  methods: {
    getUserData() {
      this.loggedUser = this.getLoggedUser;
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      this.$store.dispatch("logout");
      localStorage.removeItem("sessionKey")

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      window.location.reload();
      // this.$router.push({ name: "login" }).then(() => {
      //   makeToast(custom.infoMessages.logout);
      // });
    },
    profile() {
      this.$router.push({ name: "profile" });
    },
    leaveUserSession() {
      if (this.getIsDebugMode) {
        this.$store.dispatch("setDebugMode", false);
      } else {
        console.log("WHAT?");
      }
    },
  },
};
</script>
