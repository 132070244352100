<template>
  <div>

    <div v-if="$route.meta.showTerminal">
      <test-terminal-container title="TERMINAL OF TESTS" class="test-terminal-footer-container"/>
    </div>

    <p v-else-if="!($route.meta.hideFooter)" class="clearfix mb-0">
    
      <span class="float-md-left d-block d-md-inline-block mt-25">
        {{ $t('footer.copyright') }} {{ new Date().getFullYear() }}
        <b-link
          class="ml-25"
          href="https://fique.online/"
          target="_blank"
        >
          FiqOn
        </b-link>
        <span class="d-none d-sm-inline-block"> {{ $t('footer.all_rights_reserved') }} </span>
      </span>

      <span class="float-right">
        <patch-notes/>
      </span>
      
      <!-- <span class="float-right">
        
      </span> -->

    </p>

  </div>
</template>

<script>
import { 
  BLink, 
  BCard, 
  BCol, 
  BRow, 
  BModal
 } from 'bootstrap-vue'
import Collapse from '@/layouts/components/Terminal/Collapse.vue'
import FiqonLogo from '@/views/pages/middleware/MiddlewareManagement/Components/ComponentSVGs/FiqonLogo.vue'
import patchNotes from '@/custom/patchNotes/patchNotes.vue'
import TestTerminalContainer from '@/layouts/components/Terminal/TerminalTabs/Run/Refactored/TestTerminalContainer.vue'

export default {
  components: {
    Collapse,
    BLink,
    BCard,
    BCol, 
    BRow,
    FiqonLogo,
    BModal,
    patchNotes,
    TestTerminalContainer

  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.patch-notes-modal-content{
  max-height: 80vh;
  overflow-y: auto;
}

</style>

<style lang="scss" scoped>

.test-terminal-footer-container{
  position: fixed;
  bottom: 0px; 
  left: 0;
  width: 100%;
  z-index: 1032 !important;
}


</style>
