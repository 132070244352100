<template>
  <div>
  
    <div class="key-value-table-container">

      <table v-if="Array.isArray(parsedData)">
        <thead>
          <tr>
            <th class="border-right">
              KEY
            </th>
            <th>
              VALUE
            </th>
            <th>
              <span/>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(itm, idx) in parsedData" 
            :key="`key-value-itm-${idx}-${uuid}`"
            class="animate__animated animate__fadeIn animate__faster"
          > 
            <td>
              <b-form-input v-model="parsedData[idx].key" type="text" debounce="500" size="sm" class="borderless-input" @change="parseDataOut()"/>
            </td>
            
            <td>
              <b-form-input v-model="parsedData[idx].value" type="text" debounce="500" size="sm" class="borderless-input" @change="parseDataOut()"/>
            </td>

            <td align="center" >
              <b-button variant="none" class="py-25 px-2" @click="removeItem(itm.id)"  v-if=" itm.value.trim() != '' || itm.key.trim() != ''">
                <b-icon icon="trash" variant="danger"/>
              </b-button>
            </td>

          </tr>
        </tbody>
        
      </table>

    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BTabs,
  BTab,
  BIcon,
  BButton,
  BFormInput,
} from "bootstrap-vue";
import {v4 as uuidv4} from 'uuid'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BTabs,
    BTab,
    BIcon,
    BButton,
    BFormInput,
  },  
  data() {
    return {
      uuid: uuidv4(),
      parsedData: undefined,
      // bufferKeys:
    } 
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
  },
  mounted () {   
    this.parseDataIn()
  },
  methods: {
    parseDataIn(){
      let data = structuredClone(this.vModel)
      let parsed = []
      Object.keys(data).forEach((k)=>{
        let t = {
          id: uuidv4(),
          value: String(data[k]),
          key: String(k),
        }
        parsed.push(t)
      })
      
      parsed.push({
        id: uuidv4(),
        value: '',
        key: '',
      })
      this.parsedData = parsed
    },
    async parseDataOut(){
      await this.$nextTick()

      let data = structuredClone(this.parsedData)
      let parsed = {}
      let hasEmpty = false
      
      data.forEach((itm)=>{
        if (itm.value.trim() == '' && itm.key.trim() == ''){
          hasEmpty = true
        } else {
          let value = this.attemptParse(itm.value.trim())
          parsed[itm.key.trim()] = value 
        }
      })
      


      if (hasEmpty){
        let rParsed = []
        this.parsedData.forEach((itm)=>{
          if (itm.key.trim() != '' || itm.value.trim() != ''){
            rParsed.push(itm)
          } 
        })
        this.parsedData = rParsed
      }

      
      
      this.parsedData.push({
        id: uuidv4(),
        value: '',
        key: '',
      })
      



      this.vModel = parsed      
    },
    attemptParse(v){
      let r = v
      try{
        r = JSON.parse(v)
      } catch{}
      return r
    },
    removeItem(id){
      let data = structuredClone(this.parsedData)
      
      let idx = data.findIndex(el => el.id == id)
      if (idx > -1){
        data.splice(idx, 1)
      }
      this.parsedData = data

      this.$nextTick(()=>{
        this.parseDataOut()
      })
    }
    
    
  },
}
</script>


<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

.add-key-value-btn{
  border: 2px solid $success;
  border-radius: 50%;
  padding: 5px;
  font-size: 16px;
  color: $success;
  background-color: transparentize($success, 0.95);
  transition: all 0.1s;
  box-shadow: 0 0 0 transparent;
  outline: 1px solid transparent;
  
  &:hover, &:focus{
    transform: scale(1.2);
    box-shadow: 0 0 15px transparentize($black, 0.5);
  }
  &:active{
    outline: 1px solid $success;
  }
} 

.key-value-table-container{
  $table-border: 1px solid $default-color;
  border:  1px solid $default-color;
  border-radius: 5px;
  overflow: hidden;

  table{
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    
    th{
      padding: 10px 5px ;
      border-bottom: $table-border;
    }
    th:nth-child(1), th:nth-child(2){
      border-right: $table-border;
      width: 45%;
    }
    th:nth-child(3){
      width: 10%;
    }
    tr{
      border-bottom: $table-border;
      &:last-child{
        border-bottom: none;
      }
    }
    td{
      padding: 3px 5px;
      border-right: $table-border;
      &:last-child{
        border-right: none;
      }
    }
  }
}
.borderless-input{
  border: none;
  font-size: 14px;
  padding: 0;
  background-color: transparent !important;
}
</style>