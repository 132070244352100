<template>
<div>
  <div class="d-flex terminal-body-container">

    <div align="center" class="border-right" v-if="eventObject.method">

      <div class="p-1 border-bottom">
        <b-button 
          variant="none" 
          class="terminal-method-btn position-relative" 
          :class="eventObject.method"
          @focus="showMethodPopup = true" 
          @blur="closeMethodPopup()"
        >
          
          <span>{{eventObject.method}}</span> 

          <!-- <b-icon icon="chevron-down" scale="0.8"/> -->
          <b-icon icon="caret-down-fill" scale="0.7"/>
          <div class="method-popup-wrapper">
            <b-collapse :visible="showMethodPopup">
              <div class="method-popup-container">
                <b-button 
                  v-for="method in requestMethods" 
                  :key="'terminal-method-btn-'+method.label"
                  :variant=" (method.label == eventObject.method ? '':'outline-') + method.btn_variant " 
                  class="w-100 p-50 my-25"
                  @click="selectMethod(method.label)"
                >
                {{method.label}}
                </b-button>
              </div>
            </b-collapse>

          </div>

        </b-button>
      </div>
      
      <b-button 
        v-for="tab in tabs"
        variant="none" 
        class="custom-tab-btn"
        :class="{'active-tab': activeTab == tab.key}"
        @click="activeTab = tab.key"
        :key="'terminal-tab-btn-'+tab.key"
      >
        {{tab.label}}

        <template v-if="tab.key == 'headers'">
          <span v-if="Object.keys(eventObject.header).length > 0">[{{Object.keys(eventObject.header).length}}]</span>
        </template>

        <template v-if="tab.key == 'queries'">
          <span v-if="Object.keys(eventObject.query).length > 0">[{{Object.keys(eventObject.query).length}}]</span>
        </template>
      </b-button>

    </div>

    <div class="terminal-tab-content p-2" v-if="eventObject.header && eventObject.query">
 
      <div v-if=" activeTab == 'body' "> <!-- ==== BODY TAB ==== -->
        
        <div class="json-body-container">
          <h2 class="text-success mb-2">Body</h2>
          <div class="flex-fill">
            <json-editor
              v-if="eventObject.body != null"
              v-model="eventObject.body"
              :modes="['code', 'tree', 'preview']"
              height="100%"
              variant="success"
              class="shadow"
            >
              <template #title>
                <div class="m-50 font-weight-bold text-black">
                  ( $.DATA.BODY )
                </div>
              </template>
            </json-editor>
          </div>
        </div>
      </div>
      
      <div v-if=" activeTab == 'headers' "> <!-- ==== HEADER TAB ==== -->
        <h2 class="text-success mb-2">
        Headers
        </h2>
        <key-value-input v-model="eventObject.header" :key="'key-value-terminal-' + activeTab"/>
      </div>

      <div v-if=" activeTab == 'queries' "> <!-- ==== QUERY TAB ==== -->
        <h2 class="text-success mb-2">Queries</h2>
        <key-value-input v-model="eventObject.query" :key="'key-value-terminal-' + activeTab"/>
      </div>

    </div>
    
  </div>

  <b-modal 
    ref="advanced-modal"
    hide-footer
    centered
    v-if="eventObject && eventObject.path != null"
  >
    <template #modal-title>
      <div class="text-success font-weight-bolder">
        Advanced Setting
      </div>
    </template>
    
    <div class="mt-50 mb-2">

      <div class="mb-2">
        <span>Path</span>
        <b-form-input v-model="eventObject.path" placeholder="Ex: /dir/variable/..."/>
      </div>
      <div>
        <slot name="receiver"/>
      </div>

    </div>
  </b-modal>

</div>
</template>

<script>
import JsonEditor from "@/layouts/components/JsonEditor/JsonEditor.vue";
import Methods from "@/custom/class/Enum/Methods.js"

import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BTabs,
  BTab,
  BIcon,
  BButton,
  BFormInput,
} from "bootstrap-vue";
import KeyValueInput from '../JsonEditor/KeyValueInput.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BTabs,
    BTab,
    BIcon,
    JsonEditor,
    BButton,
    KeyValueInput,
    BFormInput,
  },
  data() {
    return {
      showMethodPopup: false,
      activeTab: 'body',
      requestMethods: new Methods().items,
      tabs:[
        {
          key: 'body',
          label: 'Body'
        },
        {
          key: 'headers',
          label: 'Headers'
        },
        {
          key: 'queries',
          label: 'Queries'
        }
      ],
    }
  },
  props: {
    value: {
      type: [Object, Array],
      required: true,
    },
  },
  computed: {
		eventObject: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			}
		},
  },
  async mounted () { 
    await this.$nextTick()

    if (this.eventObject != null && typeof this.eventObject != 'object'){

    } else if (this.eventObject == null || Object.keys(this.eventObject).length == 0){  
      await this.$nextTick()
      this.eventObject = {
        method: 'GET',
        path: '',
        body: {},
        query: {},
        header: {},
      }
    } else if ((typeof this.eventObject != 'object') ||  ((Object.keys(this.eventObject).length > 0 ) && this.eventObject.method == null)){
      let t = structuredClone(this.eventObject)
      this.eventObject = {
        method: 'GET',
        path: '',
        body: t,
        query: {},
        header: {},
      }
    } 

  },
  methods: {
    closeMethodPopup(){
      clearTimeout(this.popupTimeout)
      this.popupTimeout = setTimeout(() => {
        this.showMethodPopup = false
      }, 300);
    },
    selectMethod(method){
      this.showMethodPopup = false
      this.eventObject.method = method
    },
    showAdvancedModal(){
      this.$refs['advanced-modal'].show()
    }
  },
  
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/test-terminal.scss';

.terminal-body-container{
  background-color: $terminal-second-bg;
  border-radius: 8px;

  .custom-tab-btn{
    $tab-variant: $success;

    display: block;
    font-weight: bolder;
    color: $tab-variant;
    background-color: transparent;
    transition: all 0.25s;
    width: 100%;
    border-radius: 0;
    box-shadow: inset 0px 0px 0px 0px $tab-variant;
    outline: 1px solid transparent;
    min-width: 140px;
    &:focus{
      background-color: transparentize($tab-variant, 0.85);
    }
    &.active-tab, &:active{
      background-color: transparentize($tab-variant, 0.85);
      box-shadow: inset 3px 0px 0px 0px $tab-variant !important;
    }
    &:active{
      outline: 1px solid $tab-variant;
    }
    
  }

}

.terminal-tab-content{
  flex-grow: 1;
  height: calc(#{$terminal-height} - 120px);
  >*{
    height: 100% !important;
  }
    overflow-y: auto;
}


.terminal-method-btn{
  @mixin method-mixin($color){
    background-color: transparentize($color, 0.8);
    color: $color;
    //border: 1px solid $color;
    font-weight: bolder;
    padding:  5px 15px;
    transition: all 0.2s;
  }

  $btn-color: $success;  

  //@include method-mixin($success)

  &.GET{
    @include method-mixin($success);
  }
  &.POST{
    @include method-mixin($warning);
  }
  &.PUT{
    @include method-mixin($info);
  }
  &.DELETE{
    @include method-mixin($danger);
  }
  &.PATCH{
    @include method-mixin($secondary);
  }
}

.method-popup-wrapper{
  position: absolute;
  left: -3px;
  top: -3px;
  
  .method-popup-container{
    //transform: translateX(-50%) ;
    background-color: mix($black, $light, 95);
    width: fit-content;
    text-align: center;
    border-radius: 5px;
    padding: 5px 8px;
    z-index: 1;
  }
}

.json-body-container{
  display: flex;
  flex-direction: column;
  height: 100%;
  .flex-fill{
    flex-grow: 1;
  }
}
</style>