
export default [
  {
    title: "navigation.home",
    route: "home",
    icon: "HomeIcon",
    action: "read",
  },
  // {
  //   title: "navigation.general_dashboard",
  //   route: "integrations-dashboard",
  //   icon: "PieChartIcon",
  //   action: "read",
  //   resource: "dashboard"
  // },
  {
    title: "navigation.org_usage",
    route: "organization-usage",
    icon: "ActivityIcon",
    action: "manage",
    resource: "organization",
  },
  {
    title: "navigation.transmission",
    route: "transmission",
    icon: "PlusIcon",
    action: "manage",
  },
  {
    title: "navigation.profile",
    route: "profile",
    icon: "UserIcon",
    action: "read",
  },
  {
    title: "navigation.organization",
    route: "organization",
    icon: "GlobeIcon",
    action: "manage",
    resource: "organization",
  },
  {
    title: "navigation.connection",
    route: "connections",
    icon: "CpuIcon",
    action: "manage",
  },
  {
    title: "navigation.integrator",
    route: "integrator-service",
    icon: "PackageIcon",
    action: "manage",
  },
];
