<template>
  <b-button :type="type" :disabled="isDisabled" :variant="variant" :block="block" @click="buttonClick" :class="loading ? 'reduce-btn' : ''">
    <slot v-if="currentCountdown == 0 && !loading">
      {{ text }}
    </slot>
    <span v-else-if="!loading">
      <slot name="countdown" :currentCountdown="currentCountdown">
        {{ currentCountdown }}
      </slot>
    </span>
    <span v-else>
      <slot name="loading">
        <b-spinner small />
      </slot>
    </span>
  </b-button>
</template>

<script>
import {
  BButton,
  BSpinner,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BSpinner,
  },
  props: {
    text: {
      type: String,
      default: "",
    },
    countdown: {
      type: Number,
      required: true,
    },
    startCounting: {
      type: Boolean,
      default: false,
    },
    onClickStartCounting: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: "primary"
    },
    type: {
      type: String,
      default: "",
    },
    block: {
      type: Boolean,
      default: false,
    },
    validateClick: {
      type: Function,
      default: () => function() { return true; },
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      currentCountdown: structuredClone(this.countdown),
    };
  },
  computed: {
    isDisabled() {
      return this.currentCountdown > 0 || this.loading;
    }
  },
  mounted() {
    if(this.startCounting) {
      this.countDownTimer();
    } else {
      this.currentCountdown = 0;
    }
  },
  methods: {
    resetCountdown(){
      this.currentCountdown = 0;
    },
    startCountdown() {
      if(this.currentCountdown > 0) {
          setTimeout(() => {
            if(!this.loading && this.currentCountdown > 0) {
              this.currentCountdown -= 1
            }
            this.countDownTimer()
          }, 1000)
      }
    },
    async buttonClick(evt) {
      if(this.onClickStartCounting) {
        this.startCountdown();
      }
      this.$emit("click", evt);
    },
    click(){
      this.buttonClick()
    }
  }
}
</script>

<style lang="scss">
.reduce-btn {
  padding-top: 7px !important;
}
</style>