<template>
<div class="position-relative z-index-1">
  <div id="notification-animation"/>
  
  <b-button variant="none" class="p-50" @click="showNotifications()">
    <b-icon icon="bell" scale="1.3" variant="dark" id="notification-bell-icon"/>
    <div class="notification-counter-sign" v-if="getLoggedUser.notification_number && getLoggedUser.notification_number > 0">
      {{getLoggedUser.notification_number}}
    </div>
  </b-button>

  <div class="notifications-wrapper">
    <b-collapse :visible="show">
      
      <div class="notifications-card" @blur="delayClose()" tabindex="0" ref="notifications-card">
        <h5 align="center" class="m-0">
          <b-icon icon="bell-fill" class="mr-50"/> 
          NOTIFICATIONS
        </h5>
        <hr class="my-50">
        <div class="notifications-container">
          
          <!-- <br><br><br>
            gamer
          <br><br><br> -->
          <h4 align="center" class="text-secondary my-5">
            No Notifications
          </h4>
          <!-- <div class="rounded bg-dark-blue my-50 p-25">
            <div class="d-inline error-sign critical mr-50">
              <b-icon icon="exclamation-triangle" shift-v="2"/>
            </div>
          
            <b>Critical Error</b> 
            <b-icon icon="arrow-right-short"/>
            Transmission X, Event Y
            
          </div> -->

          <hr class="my-50">  
          <div align="center">
            <b-button variant="none" class="py-25" size="sm" :to="{name: 'profile', query: {tab: 1} }">
              View all Notifications
            </b-button>
          </div>
        </div>
      </div>
      
    </b-collapse>
  </div>
  
</div>
</template>

<script>

import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BSkeleton,
  BBadge,
  BImg,
  BInputGroupText,
  BCollapse,
} from "bootstrap-vue";
import anime from 'animejs'
import { mapGetters } from 'vuex';

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BForm,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    BSkeleton,
    BBadge,
    BImg,
    BInputGroupText,
    BCollapse,
  },
  data() {
    return {
      show: false,
      closeTimeout: undefined,
    }
  },
  computed: {
    ...mapGetters(['getLoggedUser'])
  },
  watch: {
    "getLoggedUser.notification_number":{
      handler(newValue, oldValue) {
        if ( ((oldValue == null && newValue != null) || newValue > oldValue) && newValue > 0 ){
          this.animateNotification();
        }
      },
      immediate: true,
    }
  },
  methods: {
    showNotifications() {

      // ---- REMOVE LATER ----
      if (! (this.$route.name == "profile" && String(this.$route.query.tab) == '1')  ){
        this.$router.push({name:'profile', query:{tab: 1} } )
      }
      return 
      // ---- REMOVE LATER ----

      // TODO open Notifications list
      this.show = !this.show

      if (this.show == true){
        this.$nextTick(()=>{
          this.$refs['notifications-card'].focus()
        })
      }

    },
    delayClose(){
      clearTimeout(this.closeTimeout)
      this.closeTimeout = setTimeout(() => {
        this.show = false
      }, 200);
    },
    animateNotification(){
      setTimeout(() => {

        anime({
          targets: '#notification-bell-icon',
          duration: 800,
          rotate: [0,45, -45, 0],
          easing: 'easeOutQuad',
          delay: 0,
        })
        anime({
          targets: '#notification-animation',
          duration: 1000,
          padding: [0, '50px'],
          opacity: [1, 0],
          easing: 'easeOutQuad',
          delay: 500,
        })

      }, 3000);

    }
    
  },
}
</script>


<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';
.z-index-1{
  z-index: 1;
}

.notification-counter-sign{
  position: absolute;
  z-index: 10;
  right: -1px;
  background-color: $danger;
  top: -5px;
  width: fit-content;
  color: $light;
  border-radius: 50%;
  padding: 2px 5px;
  font-weight: bolder;
  font-size: 14px;
}

.notifications-wrapper{
  position: absolute;
  top: 40px;
  right: 10px;
  .notifications-card{
    position: relative;
    width: 300px;
    background-color: $card-blue;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid mix($black, $card-blue, 40);
    .notifications-container{
      max-height: 30vh;
      overflow-y: auto;
    }
  }
}


.error-sign{
  $sign-color: $danger;
  padding: 3px 5px;
  background-color: transparentize( $sign-color, 0.7);
  border-radius: 50%;
  color: $sign-color
}

#notification-animation{
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $danger;
  padding: 0;
  border-radius: 50%;
}
</style>