<template>
  <div
    v-if="$can('create')"
    class="terminal-outer-container"
  >
    <div class="terminal-visible-border" @click="toggleVisibility()" tabindex="0">
      <div class="terminal-title">
        <feather-icon icon="TerminalIcon" class="mr-25"/>
        TERMINAL OF TESTS
      </div>
    </div>
    
      <b-collapse
        v-model="visible"
      >
        <div class="px-1 mt-50">
          <b-tabs v-model="tabActive" class="terminal-custom-tab" :key="'terminal-tabs-container-'+terminalRefreshKey"> 
            <b-tab
              v-for="(tab, idx) in tabs"
              :key=" 'test-run-tab-'+ tab.key +'-'+ idx"
            >
              <template #title>
                <span class="py-0" :class="tab.closable ? '' : 'text-success'">
                  <b-icon v-if="tab.titleIcon" :icon="tab.titleIcon" variant="success" class="p-0 ml-0 mr-25" shift-v="-1"/>
                  {{ $t(tab.name) }}
                </span>

                <span v-if="tab.closable" class="ml-25 mx-0 px-25 pr-0 hover-danger" @click="closeTab(tab.key)">
                  <b-icon class="" icon="x" variant="white"/>
                </span>
              </template>

              <hr class="mb-1 negative-mt-hr">

              <div class="control-terminal-height">
                <component
                  :key="`component-tab-${idx}-${getReactiveTerminal}`"
                  :is="tab.component"
                  @addTab="addTab"
                  @forceTerminalOpen="toggleVisibility(true)"
                  v-bind="tab.properties"
                  :isOpen="visible"
                />
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-collapse>

  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BTabs,
  BTab,
  BIcon,
} from "bootstrap-vue";
import tabs from "@/layouts/components/Terminal/TerminalTabs"
import { v4 as uuidv4 } from "uuid";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import TestTerminal from "@/layouts/components/Terminal/TerminalTabs/TestTerminal.vue";
import TestTimeline from "@/layouts/components/Terminal/TerminalTabs/Timeline.vue";
import RunLog from "@/layouts/components/Terminal/TerminalTabs/Run/RunLog.vue";
import RunLogInfo from "@/layouts/components/Terminal/TerminalTabs/Run/Refactored/RunLogInfo.vue"
import {mapGetters} from 'vuex'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BTabs,
    BTab,
    BIcon,
    TestTerminal,
    TestTimeline,
    RunLog,
    VuePerfectScrollbar,
    RunLogInfo,
  },
  props: {
    title: {
      type: String,
      default: "Title",
    },
  },
  data() {
    return {
      tabs,
      visible: false,
      collapseItemID: "",
      openOnHover: this.$parent.hover,
      tabsAlreadyOpen: {},
      tabActive: 0,
      terminalRefreshKey: 0,
    };
  },
  computed: {
    ...mapGetters('internal', ['getEventChangedTrigger', 'getReactiveTerminal']),
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null;
    },
  },
  watch: {
    getEventChangedTrigger(newValue, oldValue) {
      this.terminalRefreshKey = this.terminalRefreshKey + 1
    }
  },
  mounted() {
    this.collapseItemID = uuidv4();
  },
  methods: {
    toggleVisibility(direction = undefined){
      if (direction != undefined){
        this.visible = direction  
      } else {
        this.visible = !this.visible;
      }
      
      this.$store.dispatch("internal/setSidebarState", this.visible)
    },
    closeTab(key) {
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].key === key) {
          this.tabsAlreadyOpen[key] = false;

          this.tabs.splice(i, 1);
        }
      }
    },
    addTab(tab) {
      if (!this.tabsAlreadyOpen[tab.key]) {
        this.tabsAlreadyOpen[tab.key] = true;
        const i = this.tabs.push(tab);

        if (tab.createOpenned) {
          setTimeout(() => {
            this.tabActive = i - 1;
          }, 250);
        }
      } else {
        let idx = this.tabs.findIndex((el)=> el.key == tab.key)
        if (idx > -1){
          this.tabActive = idx;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/test-terminal.scss';


.negative-mt-hr{
  margin-top: -8px;
  border-color: transparentize($light,  0.9);
}
.terminal-outer-container{
  background-color: $terminal-bg;
}

.control-terminal-height{
  padding: 0;
  margin: 0;
  height: $terminal-height;
  min-height: $terminal-height;
  max-height: $terminal-height;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.terminal-visible-border{
  border-top: $terminal-border;
  background-color: $terminal-bg;
  height: 5px;
  cursor: pointer;
  .terminal-title{
    position: absolute;
    top: -0px;
    transform: translateY(-100%);
    right: 150px;
    background-color: $terminal-bg;
    padding: 4px 10px;
    border-radius: 6px 6px 0 0; 
    border: $terminal-border;
    border-bottom: none;
    font-family: monospace;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    &:hover , &:focus{
      background-color: lighten($terminal-bg,  3);
    }

  }
}

.hover-danger {
  transition: all 0.1s ease-in;
  &:hover , &:hover *{
    color: $danger !important;
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/custom-utils.scss';

.terminal-custom-tab{
  background-color: transparent;
  .nav-link{
    font-weight: bolder;
    &.active{
      background-color: transparentize($light, 0.95) !important;
    }
  }
}
</style>