import { render, staticRenderFns } from "./CompileButton.vue?vue&type=template&id=70eb983d&scoped=true"
import script from "./CompileButton.vue?vue&type=script&lang=js"
export * from "./CompileButton.vue?vue&type=script&lang=js"
import style0 from "./CompileButton.vue?vue&type=style&index=0&id=70eb983d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70eb983d",
  null
  
)

export default component.exports